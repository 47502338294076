<template>
  <v-app class="main_content">
    <Header></Header>

    <v-banner
        elevation="3"
        outlined
        rounded
        class="formInfo"
    >Application Form for Shop Establishment Intimation or Shop & Establishment Registration (for 0 to 9 workers) Form -
      'F'
    </v-banner>
    <v-form lazy-validation ref="form" v-model="valid" v-on:keyup.enter.prevent="submit">
      <v-card-text>

        <v-text-field
            v-model="formData.full_name"
            label="Full Name"
            type="text"
            :rules="rules.fullName"
            ref="fullname"
            v-on:blur="getOrderId()"
            outlined
        >
        </v-text-field>
        <v-text-field
            v-model="formData.mobile_number"
            label="Mobile Number"
            type="number"
            :rules="rules.mobileNumber"
            ref="mobileNumber"
            v-on:blur="getOrderId()"
            outlined
        >
        </v-text-field>
        <v-text-field
            v-model="formData.aadhaar_number"
            label="Aadhaar Number"
            type="number"
            :rules="rules.aadhaarNumber"
            outlined
        >
        </v-text-field>
        <v-text-field
            v-model="formData.email_id"
            label="Email Id"
            type="text"
            outlined
            ref="email"
            :rules="rules.emailId"
            v-on:blur="getOrderId()"
        >
        </v-text-field>
        <v-select
            v-model="formData.gender"
            label="Gender"
            type="text"
            :items="['Male', 'FeMale']"
            outlined
            :rules="rules.gender"
        >
        </v-select>
        <v-menu
            v-model="dobMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Date of Birth"
                readonly
                :value="formData.dob"
                v-on="on"
                v-on:click="on"
                :rules="rules.dob"
            ></v-text-field>
          </template>
          <v-date-picker
              locale="en-in"
              v-model="formData.dob"
              no-title
              elevation="2"
              @input="dobMenu = false"
          ></v-date-picker>
        </v-menu>
        <!-- Residential Address Fields Start -->
        <div>
          <v-banner
              color="#EAEFF2"
              elevation="2"
              rounded
              single-line
              style="font-size: large; text-align: center"
          >Residential Information
          </v-banner>
          <br>
          <v-text-field
              v-model="formData.residential_address"
              label="Residential Address"
              type="text"
              outlined
              :rules="rules.residentialAdd"
              counter="50"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.residential_district"
              label="Residential District"
              type="text"
              outlined
              :rules="rules.residentialDistrict"
              counter="50"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.residential_taluka"
              label="Residential Taluka"
              type="text"
              outlined
              :rules="rules.residentialTaluka"
              counter="50"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.residential_pincode"
              label="Residential PIN Code"
              type="number"
              outlined
              :rules="rules.residentialPinCode"
          >
          </v-text-field>
        </div>
        <!-- Residential Address Fields End -->

        <!-- Details of Organization Start -->
        <div>
          <v-banner
              color="#EAEFF2"
              elevation="2"
              rounded
              style="font-size: large; text-align: center"
          >Details of Organization / Business / Enterprise
          </v-banner>
          <br>
          <v-text-field
              v-model="formData.name_of_business"
              label="Name of Business"
              type="text"
              outlined
              :rules="rules.nameOfBusiness"
              counter="50"
          >
          </v-text-field>
          <v-select
              v-model="formData.type_of_business"
              label="Type of Organization"
              type="text"
              outlined
              :rules="rules.typeOfBusiness"
              :items="['Proprietorship', 'Partnership', 'Private Limited Company','Public Limited Company','Limited Liability Partnership','Trust','Co-operative Society']"
          >
          </v-select>
          <v-select
              v-model="formData.ownership_status_business"
              label="Ownership of Business"
              type="text"
              outlined
              :rules="rules.ownershipStatusBusiness"
              :items="['Rented','Self-Owned']"
          >
          </v-select>
          <v-menu
              v-model="dateOfCommencementMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  label="Date of Commencement"
                  readonly
                  :value="formData.date_of_commencement"
                  v-on="on"
                  v-on:click="on"
                  :rules="rules.dateOfCommencement"
              ></v-text-field>
            </template>
            <v-date-picker
                locale="en-in"
                v-model="formData.date_of_commencement"
                no-title
                elevation="2"
                @input="dateOfCommencementMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              v-model="formData.male_employees"
              label="Male Employees"
              type="number"
              outlined
              :rules="rules.maleEmployees"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.female_employees"
              label="FeMale Employees"
              type="number"
              outlined
              :rules="rules.femaleEmployees"
          >
          </v-text-field>
        </div>
        <!-- Details of Organization End -->

        <!-- Postal Address Start -->
        <div>
          <v-banner
              color="#EAEFF2"
              elevation="2"
              rounded
              style="font-size: large; text-align: center"
          >Postal address of Enterprise / Business
          </v-banner>
          <br>
          <v-text-field
              v-model="formData.business_address"
              label="Address"
              type="text"
              outlined
              :rules="rules.businessAddress"
              counter="50"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.business_taluka"
              label="Taluka"
              type="text"
              outlined
              :rules="rules.businessTaluka"
              counter="50"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.business_district"
              label="District"
              type="text"
              outlined
              :rules="rules.businessDistrict"
              counter="50"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.business_pincode"
              label="PIN Code"
              type="number"
              outlined
              :rules="rules.businessPinCode"
          >
          </v-text-field>
          <v-text-field
              v-model="formData.business_description"
              label="Nature of Business ( A short Description)"
              type="text"
              outlined
              :rules="rules.businessDescription"
              counter="50"
          >
          </v-text-field>
        </div>
        <!-- Postal Address End -->
        <v-select
            v-model="formData.type"
            label="Type of Registration"
            type="text"
            :items="['New', 'Renewal']"
            :rules="rules.type"
            outlined
            v-on:blur="getOrderId"
        >
        </v-select>
        <v-radio-group inline label="Length of Registration" v-model="formData.reg_length"
                       v-if="formData.type === 'New'" v-on:change="getOrderId" :rules="rules.reg_length">
          <v-radio label="1 Year Validity Rs 299" value="1yr">1yr</v-radio>
          <v-radio label="3 Year Validity Rs 799" value="3yr">3yr</v-radio>
          <v-radio label="Lifetime Validity Rs 979" value="Lf">Lf</v-radio>
        </v-radio-group>
        <!-- Image Upload Start -->
        <div>
          <v-file-input
              label="Aadhaar Card Image"
              show-size
              v-model="formData.aadhaar_image"
          ></v-file-input>
          <v-file-input
              label="Pan Card Image"
              show-size
              v-model="formData.pan_image"
          ></v-file-input>
        </div>
        <!-- Image Upload End -->

        <v-checkbox
            v-model="formData.tc_check"
            label="I Agree to Terms and Conditions"
            :rules="rules.tcCheck"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green" type="submit" v-on:click.prevent="submit" :disabled="!valid">Submit & Pay</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-form>
    <v-divider></v-divider>
    <br>
    <div class="text-center">
      <v-snackbar
          v-model="formValidateSnackBar"
          :timeout="2000"
          color="red accent-4"
          elevation="24"
      >
        Form Incomplete

        <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="formValidateSnackBar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
          v-model="snackbarCheck"
          :timeout="2000"
          color="error"
      >
        {{ errorText }}
      </v-snackbar>
    </div>
    <v-overlay v-if="uploading">
      <v-progress-circular
          :width="3"
          color="red"
          indeterminate
          class="loader">
      </v-progress-circular>
      <br>
      <span style="font-size: larger;">Loading, Please Wait</span>
    </v-overlay>
    <Footer></Footer>

  </v-app>
</template>

<script>
/* eslint-disable no-unreachable */

import Header from "@/components/header";
import Footer from "@/components/footer";
import axios from "axios";
import * as AWS from "aws-sdk";

export default {
  name: 'Home',
  components: {
    Header, Footer
  },
  data() {
    return {
      orderIdProd: "https://api.shopactreg.in/order_creation_prod",
      orderIdStage: "https://api.shopactreg.in/order_creation_stage",
      valid: true,
      dobMenu: false,
      dateOfCommencementMenu: false,
      price: null,
      formValidateSnackBar: false,
      paytmJsScriptStage: "https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/QTGFGh33950010312386.js",
      paytmJsScriptProd: "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/BbPjwp68926025668260.js",
      token: null,
      requestStart: false,
      errorText: "",
      snackbarCheck: false,
      uploading: false,
      formData: {
        full_name: null,
        mobile_number: null,
        order_id: null,
        mid: "",
        TXNAMOUNT: "",
        type: null,
        aadhaar_number: null,
        email_id: null,
        dob: null,
        gender: null,
        residential_address: null,
        residential_district: null,
        residential_taluka: null,
        residential_pincode: null,
        name_of_business: null,
        type_of_business: null,
        ownership_status_business: null,
        date_of_commencement: null,
        male_employees: null,
        female_employees: null,
        business_address: null,
        business_taluka: null,
        business_district: null,
        business_pincode: null,
        business_description: null,
        aadhaar_image: null,
        pan_image: null,
        aadhaar_image_name: "",
        pan_image_name: "",
        reg_length: null,
        tc_check: false
      },
      rules: {
        type: [
          value => !!value || 'Required',
        ],
        fullName: [
          value => !!value || 'Required',
        ],
        mobileNumber: [
          value => !!value || 'Required',
          value => (value && value.length === 10) || 'Mobile Number must be 10 numbers',
        ],
        aadhaarNumber: [
          value => !!value || 'Required',
          value => /^\d{4}[\s-]?\d{4}[\s-]?\d{4}$/.test(value) || 'Not valid Aadhaar Number',
        ],
        emailId: [
          value => !!value || 'Required.',
          value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'E-mail must be valid',
        ],
        dob: [
          value => !!value || 'Required',
        ],
        gender: [
          value => !!value || 'Required',
        ],
        residentialAdd: [
          value => !!value || 'Required',
          value => (value && value.length) <= 50 || 'Max 50 characters',
        ],
        residentialDistrict: [
          value => !!value || 'Required',
          value => (value && value.length) <= 50 || 'Max 50 characters',

        ],
        residentialTaluka: [
          value => !!value || 'Required',
          value => (value && value.length) <= 50 || 'Max 50 characters',
        ],
        residentialPinCode: [
          value => !!value || 'Required',
          value => (value && value.length === 6) || 'PIN code must be 6 numbers',

        ],
        nameOfBusiness: [
          value => !!value || 'Required',
        ],
        typeOfBusiness: [
          value => !!value || 'Required',
        ],
        ownershipStatusBusiness: [
          value => !!value || 'Required',
        ],
        dateOfCommencement: [
          value => !!value || 'Required',
        ],
        maleEmployees: [
          value => !!value || 'Required',
        ],
        femaleEmployees: [
          value => !!value || 'Required',
        ],
        businessAddress: [
          value => !!value || 'Required',
          value => (value && value.length) || 'Max 50 characters',
        ],
        businessTaluka: [
          value => !!value || 'Required',
          value => (value && value.length) || 'Max 50 characters',
        ],
        businessDistrict: [
          value => !!value || 'Required',
          value => (value && value.length) || 'Max 50 characters',
        ],
        businessPinCode: [
          value => !!value || 'Required',
          value => (value && value.length === 6) || 'PIN code must be 6 numbers',

        ],
        businessDescription: [
          value => !!value || 'Required',
          value => (value && value.length) || 'Max 50 characters',
        ],
        tcCheck: [
          value => !!value || 'Accept Terms and Conditions',
        ],
        reg_length: [
          value => !!value || 'Required',
        ],
      },
    }
  },
  watch: {
    formData: {
      handler(val) {
        if (val.type !== null && this.$refs.email.validate() && this.$refs.mobileNumber.validate() && this.$refs.fullname.validate()) {
          this.requestStart = true;
        }
      },
      deep: true
    }
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        // eslint-disable-next-line
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    getOrderId() {
      console.log(this.requestStart);
      console.log("Type CHECK", this.formData.type === null && this.formData.type === "New");
      console.log("CHECK", this.formData.reg_length === null);
      if (this.formData.type === null)
        return;
      else if (this.formData.type === "New" && this.formData.reg_length === null)
        return;
      console.log(this.formData);
      this.formData.aadhaar_image_name = "Not Given";
      this.formData.pan_image_name = "Not Given";
      let that = this;
      const snapshot = {
        url: 'https://api.shopactreg.in/submit',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(that.formData)
      };
      axios(snapshot);
      this.formData.aadhaar_image_name = null;
      this.formData.pan_image_name = null;
      let data = {
        "name": this.formData.full_name,
        "email": this.formData.email_id,
        "mobile": this.formData.mobile_number,
        "type": this.formData.type.toUpperCase(),
        "validity": this.formData.reg_length,
        "mobileCheck": this.isMobile()
      }
      const options = {
        url: this.orderIdProd,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(options)
          .then(response => {
            if (response.status === 200) {
              this.formData.order_id = response.data['OrderId'];
              this.price = response.data['Price'];
              this.token = response.data['Token'];
              console.log(response.data);
            }
          })
          .catch(function (error) {
            that.errorText = error.response.data.details;
            that.snackbarCheck = true;
          });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$gtag.event('begin_checkout', {
          'event_category': 'ecommerce',
          'event_label': 'tried_payment'
        })
        this.pay();
      } else {
        this.formValidateSnackBar = true;
      }
    },
    pay() {
      try {
        this.formData.pan_image_name = this.formData.pan_image.name.toString();
      } catch (e) {
        if (e instanceof TypeError) {
          this.formData.pan_image_name = "Not Given";
        }
      }
      try {
        this.formData.aadhaar_image_name = this.formData.aadhaar_image.name.toString();
      } catch (e) {
        if (e instanceof TypeError) {
          this.formData.aadhaar_image_name = "Not Given";
        }
      }
      var that = this;
      var config = {
        "root": "",
        "flow": "DEFAULT",
        "data": {
          "orderId": this.formData.order_id.toString() /* update order id */,
          "token": this.token.toString() /* update token value */,
          "tokenType": "TXN_TOKEN",
          "amount": this.price.toString() /* update amount */
        },
        merchant: {
          redirect: false
        },
        "handler": {
          "notifyMerchant": function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
          },
          "transactionStatus": async function transactionStatus(paymentStatus) {
            if (paymentStatus['STATUS'] === 'TXN_SUCCESS') {
              that.formData.TXNAMOUNT = paymentStatus['TXNAMOUNT'];
              that.formData.mid = paymentStatus['MID'];
              window.Paytm.CheckoutJS.close();
              that.uploading = true;
              const options = {
                url: 'https://api.shopactreg.in/submit',
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify(that.formData)
              };
              let there = that;
              axios(options)
                  .then(response => {
                    if (response.status === 200) {
                      console.log("Success");
                      there.uploadImage();
                      there.uploading = false;
                      there.$gtag.event('payment', {
                        'event_category': 'ecommerce',
                        'event_label': 'payment_success',
                      })
                      localStorage.setItem("PaymentFlag", "Paid")
                      there.$router.push("/thankyou");
                    }
                  })
                  .catch(function (error) {
                    there.errorText = error.response.data.details;
                    there.snackbarCheck = true;
                  });
            } else {
              this.snackbarCheck = true;
              this.errorText = "Payment Failed"
            }
          }
        }
      };
      window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
        // after successfully update configuration invoke checkoutjs
        window.Paytm.CheckoutJS.invoke();
      }).catch(function onError(error) {
        console.log("error => ", error);
      });
    },
    async uploadImage() {
      var bucketName = 'osmium-consultancy';
      var bucketRegion = 'ap-south-1';
      var IdentityPoolId = 'ap-south-1:1ede8e01-9acd-4790-8c7f-3a1ba0efa14b';
      AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: IdentityPoolId
        })
      });
      if (this.formData.aadhaar_image_name !== "Not Given") {
        console.log("IMAGE UPLOAD STARTED")

        var file = this.formData.aadhaar_image;
        var fileName1 = "images/" + this.formData.order_id + "_aad" + "." + this.formData.aadhaar_image_name.substring(this.formData.aadhaar_image_name.indexOf(".") + 1,);
        var upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: bucketName,
            Key: fileName1,
            Body: file
          }
        });
        await upload.promise().then(/*response => console.log("UPLOADED", response)*/)
            .catch(function (err) {
              alert("Aadhaar Upload Error")
              console.log(err);
            });
      }
      if (this.formData.pan_image_name !== "Not Given") {
        file = this.formData.pan_image;
        fileName1 = "images/" + this.formData.order_id + "_pan" + "." + this.formData.pan_image_name.substring(this.formData.pan_image_name.indexOf(".") + 1,);
        upload = new AWS.S3.ManagedUpload({
          params: {
            Bucket: bucketName,
            Key: fileName1,
            Body: file
          }
        });
        await upload.promise().then(/*response => console.log("UPLOADED", response)*/)
            .catch(function (err) {
              alert("Pan Card Upload Error")
              console.log(err);
            });
      }
    }
  }
  ,
  mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', this.paytmJsScriptProd)
    document.head.appendChild(externalScript);
  }
}
</script>

<style scoped>

.formInfo {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 52vw;
  color: red;
  font-weight: bold;
  font-size: large;
}

.loader {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
}

@media only screen and (max-width: 600px) {

  .formInfo {
    max-width: 90vw;
    font-size: initial;
    font-weight: initial;
  }

}

.main_content {
  overflow-x: hidden;
}


</style>